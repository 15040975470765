export const addForm = {
    list: [],
    start: '',
    end: '',
    id: '',
    pageSize: 10,
    currentPage: 1
};

export const addRules = {
    start: [{
        required: true,
        message: '请选择开启时间',
        trigger: 'blur'
    }]
};

export const tableColumns = [
    { prop: 'preview', label: '缩略图', width: 160, align: 'left' },
    { prop: 'origin_id', label: '内部ID', width: 180, align: 'left' },
    { prop: 'asset_uid', label: '外部ID', width: 180, align: 'left' },
    { prop: 'chinese_name', label: '中文名称', width: 160, align: 'left' },
    { prop: 'name', label: '英文名称', width: 160, align: 'left' },
    // { prop: 'cost', label: '原价', width: 100, align: 'left' },
    { prop: 'published_at', label: '发布时间', width: 160, align: 'left' },
    { prop: 'is_scene_package', label: '是否为场景包资产', minWidth: 160, align: 'left' },
    { prop: 'operation', label: '操作', align: 'left', width: 160, fixed: 'right' }
];
