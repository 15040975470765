<template>
  <div class="aops-add-free-assets">
     <div class="aop-back" @click="back">
        <i class="iconfont aop-back-icon">&#xe647;</i><span>返回</span>
    </div>
      <el-form
        ref="form"
        :model="form"
        label-width="90px"
        label-position="left"
        :rules="rules"
        class="aop-free-list"
      >
        <div class="aop-free-title">设置免费时间</div>
        <el-form-item label="开启时间" prop="start" class="aop-select-time">
          <el-date-picker
            v-model="form.start"
            style="width: 198px;"
            clearable
            type="datetime"
            popper-class="expired"
            :picker-options="pickerOptions"
            value-format="timestamp"
            prefix-icon="none"
            placeholder="请选择"/>
        </el-form-item>
        <el-form-item label="结束时间" class="aop-select-time">
          <el-date-picker
            v-model="form.end"
            style="width: 198px;"
            clearable
            type="datetime"
            popper-class="expired"
            :picker-options="pickerOptions"
            value-format="timestamp"
            prefix-icon="none"
            placeholder="请选择"/>
        </el-form-item>
        <div class="aop-free-title">添加资产</div>
         <el-form-item label="外部ID" class="aop-input-id" >
           <el-input
                v-model="form.id"
                placeholder="输入ID（精准搜索）"
                @click="searchAsset"
                @keyup.enter.native="addByEnterKey"
            />
            <span class="aop-input-tips">按回车键搜索添加</span>
            <!-- <div v-if="searchsData.length > 0" class="aop-add-btn" @click="addSearchAsset">+</div> -->
        </el-form-item>
        <el-table
            :data="curPageList"
            class="data-table"
            header-cell-class-name="table-head"
            cell-class-name="table-cell"
        >
            <el-table-column
                v-for="col in tableColumns"
                :key="col.label"
                :prop="col.prop"
                :label="col.label"
                :width="col.width"
                :align="col.align"
                :min-width="col.minWidth"
                :fixed="col.fixed">
                <template slot-scope="{row}">
                    <div v-if="col.prop === 'preview'">
                    <img :src="row.preview+ '?imageMogr2/thumbnail/x128/ignore-error/1/interlace/1'" class="aop-preview-url" alt=""/>
                    </div>
                    <div v-else-if="col.prop === 'is_scene_package'">
                        {{row[col.prop]? '是': '否'}}
                    </div>
                    <div v-else-if="col.prop === 'published_at'">{{publishedAt(row[col.prop])}}</div>
                    <div v-else-if="col.prop === 'operation'" class="icon-btn">
                        <el-button
                            size="mini"
                            type="text"
                            @click="remove(row)" >
                            <i class="iconfont">&#xe678;</i>
                            删除
                        </el-button>
                    </div>
                    <div v-else>{{row[col.prop] || '--'}}</div>
                </template>
            </el-table-column>
      </el-table>
      <div v-if="form.list.length > 0" class="aop-pagination">
        <el-pagination
          :current-page.sync="form.currentPage"
          :page-size="form.pageSize"
          :page-sizes="[10, 20, 50]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="form.list.length"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
      </el-form>
    <div class="aop-free-btn" >
        <el-button v-loading="btnLoading" class="black-btn aop-btn-size" @click="confirmAdd">确 认</el-button>
        <el-button class="white-btn aop-btn-size" @click="back">取 消</el-button>
      </div>
  </div>
</template>
<script>
import { formatDate } from 'ecoplants-lib';
import api from '../../../../api/assets';
import { addRules, addForm, tableColumns } from './data';

export default {
    /**
    * 基础数据&定义数据
     @return{*}
    */
    data() {
        return {
            dialogVisible: false,
            form: { ...addForm },
            rules: addRules,
            tableColumns,
            btnLoading: false,
            searchsData: []
        };
    },
    computed: {
        /**
        * 时间范围
        * @returns {*}
        */
        pickerOptions() {
            const pickerOptions = {
            /** 时间范围
            * @param {*}time 值
              @returns {*}
            */
                disabledDate(time) {
                    return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
                }
            };
            return pickerOptions;
        },
        /**
        * 时间范围
        * @returns {*}
        */
        curPageList() {
            return this.form.list.slice((this.form.currentPage - 1) * this.form.pageSize, this.form.currentPage * this.form.pageSize);
        }
    },
    /** * 初始化 */
    mounted() {
        this.init();
    },
    methods: {
        /** *初始化 */
        init() {
            this.form = { ...addForm, list: [] };
        },
        /** * 确认提交 */
        confirmAdd() {
            if (!this.form.list.length) {
                this.$$error('请添加资产');
                return;
            }
            if (this.form.start && this.form.end && this.form.end <= this.form.start) {
                this.$$error('结束时间不能小于等于开始时间哦');
                return;
            }
            if (this.form.end && this.form.end <= Date.now()) {
                this.$$error('结束时间不能小于当前时间');
                return;
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    const data = {
                        start_time: this.form.start && this.form.start / 1000,
                        end_time: (this.form.end && this.form.end / 1000) || 0,
                        asset_uids: this.form.list.map((item) => item.asset_uid) || []
                    };
                    api.addFree(data).then((resp) => {
                        if (resp.data.code === 10200) {
                            this.$$success('免费资产保存成功!');
                            this.back();
                        } else {
                            this.$$error(resp.data.message);
                        }
                        this.btnLoading = false;
                    });
                }
            });
        },
        /** * 取消 */
        back() {
            this.form = { ...addForm, list: [] };
            this.$router.push('/assets/free');
        },
        /**
        * 查询
         * @param {*} e 值
        */
        addByEnterKey(e) {
            if (e.keyCode == 13) {
                this.searchAsset();
            }
        },
        /**
        * 查询
         * @param {*} val 值
        */
        handleSizeChange(val) {
            this.form.pageSize = val;
        },
        /**
        * 查询
         * @param {*} val 值
        */
        handleCurrentChange(val) {
            this.form.currentPage = val;
        },
        /**
         * 添加资产
         */
        addSearchAsset() {
            if (this.searchsData.length && this.searchsData[0].status !== 5) {
                this.$$error('该资产还没有发布哦');
                this.searchsData = [];
                return;
            }
            if (this.searchsData.length && !this.form.list.some((item) => (item.asset_uid == this.searchsData[0].asset_uid))) {
                this.form.list.push(this.searchsData[0]);
                this.searchsData = [];
                this.form.id = '';
                this.$$success('添加成功');
            } else {
                this.form.id = '';
                this.searchsData = [];
                this.$$error('不能重复添加');
            }
        },
        /**
         * 筛选资产
         */
        searchAsset() {
            if (!this.form.id) {
                return;
            }
            const data = this.form.id;
            this.searchsData = [];
            api.assetsInfo(data).then((res) => {
                if (res.data.code === 10200) {
                    const resData = res.data.data;
                    this.searchsData.push(resData);
                    // this.searchsData = new Set(this.searchsData);
                    this.addSearchAsset();
                } else {
                    this.$$error(res.data.message);
                }
                this.isTableLoading = false;
            });
        },
        /**
         * 删除资产
         * @param {*} row 值
         */
        remove(row) {
            this.form.list = this.form.list.filter((val) => val.id != row.id);
        },
        /**
         * 计算是否为场景包资产
         * @param {*} val 值
         @returns {*}
         */
        publishedAt(val) {
            return val ? formatDate(val * 1000, 'yyyy-MM-dd hh:mm:ss') : '--';
        }
    }
};
</script>

<style lang="less">
@import "./add.less";
</style>
